const networkConfig = {
  keycloakExternalIDP: 'simeio-st',
  keycloakBaseUrl: 'https://iam.sit.myhealth.gnb-doh.vsfcloud.com/auth/',
  patientDataUrl: 'https://sit.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/patient/',
  medicationUrl: 'https://sit.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/medication/',
  immunizationUrl: 'https://sit.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/immunization/',
  observationUrl: 'https://sit.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  diagnosticReportUrl: 'https://sit.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticReport/',
  imagingUrl: 'https://sit.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/diagnosticImagingReport/',
  alertUrl: 'https://sit.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/alert/',
  redirectUri: 'https://myhealth-web.sit.myhealth.gnb-doh.vsfcloud.com/',
  loginUrl: 'https://sit.myhealth.gnb-doh.vsfcloud.com/myhealth-gateway/v1/authentication/login',
  authorizeUrl: 'https://access-sit.id.gnb.ca/as/authorization.oauth2',
  clientId: 'MHNBPHRCLIENT',
  loginProviderType: 'myhealth_broker_sit',
};

export default networkConfig;
